import { mockFhotos } from '@/features/slider-default/model/mock-slider-default';
import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render';
import { animatedCirclesTextBlock, wordsInCircles } from '@/features/animated-circles/model/mock-animated-circles';

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'SliderDefault',
    props: {
      images: mockFhotos,
    },
  },
  {
    component: 'AnimatedCircles',
    props: {
      textBlock: animatedCirclesTextBlock,
      wordsInCircles: wordsInCircles,
    },
  },
];
