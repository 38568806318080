import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { MainLayout } from '@/app/main-layout';
import { VideoPlayer } from '@/features/video-player';
import { BrokerPage } from '@/pages/broker-page';
import { ContactPage } from '@/pages/contact-page';
import { EmployerPage } from '@/pages/employer-page';
import { TendersCooperationPage } from '@/pages/tenders-cooperation-page';
import { InfoCenterNewsPage } from '@/pages/info-center-news-page';
import { InfoCenterAllNewsPage } from '@/pages/info-center-all-news-page';
import { SelectionLayout } from './selection-layout';
import { RoompickerPage } from '@/pages/roompicker-page';
import { RealisationPage } from '@/pages/realisation-page';
import { PrivacyPolicyPage } from '@/pages/privacy-policy-page';
import { routes } from '@/shared/constants/routes.ts';
import { mockVideoData } from '@/features/video-player/model/mock-video-player';
import { FineDevelopmentPage } from '@/pages/fine-development';
import { FullScreenVideoPlayer } from '@/features/full-screen-video-player/ui';
import { mockVideo } from '@/shared/mockData/mock-video';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainLayout />}>
        <Route path={routes.public.allNewsPage} element={<InfoCenterAllNewsPage />} />
        <Route path={routes.public.oneNewsPage} element={<InfoCenterNewsPage />} />
        <Route path={routes.public.bestEmployerPage} element={<EmployerPage />} />
        <Route path={routes.public.contactsPage} element={<ContactPage />} />
        <Route path={routes.public.brokerPage} element={<BrokerPage />} />
        <Route path={routes.public.realisationPage} element={<RealisationPage />} />
        <Route path={routes.public.videoPlayer} element={<VideoPlayer url={mockVideoData.url} />} />
        <Route path={routes.public.tendersCooperationPage} element={<TendersCooperationPage />} />
        <Route path={routes.public.fineDevelopmentPage} element={<FineDevelopmentPage />} />
        <Route path={routes.public.privacyPolicyPage} element={<PrivacyPolicyPage />} />
        <Route path={routes.public.roompicker} element={<RoompickerPage />} />
        <Route
          path={'/full-width-player'}
          element={
            <FullScreenVideoPlayer
              previewUrl={mockVideo[0].previewLink}
              videoUrl={mockVideo[0].video}
              playBtnLabel="Смотреть"
              isPlayBtn={true}
              text="БЕСКОМПРОМИССНЫЙ ДОМ С ЛУЧШИМ ВИДОМ НА КРЕМЛЬ"
            />
          }
        />
      </Route>
      <Route path={routes.public.selectionLayout} element={<SelectionLayout />}>
        <Route path="details" element={<BrokerPage />} />
      </Route>
    </Route>
  )
);
