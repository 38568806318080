import React, { useEffect, useRef, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useIsMobile from '@/shared/hooks/useIsMobile';

gsap.registerPlugin(ScrollTrigger);

interface ScrollingWrapperProps {
  sliderComponent: React.ReactNode;
  textBlockComponent: React.ReactNode;
  isSliderRight?: boolean;
}

export const ScrollingWrapper: React.FC<ScrollingWrapperProps> = ({
  sliderComponent,
  textBlockComponent,
  isSliderRight,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  const REM_TO_PX = (rem: number) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

  useEffect(() => {
    if (!isMobile && sliderRef.current && textRef.current) {
      const REM_BEFORE_COMPONENT = 5.6555;
      const trigger = ScrollTrigger.create({
        trigger: textRef.current,
        start: `top-=${REM_TO_PX(REM_BEFORE_COMPONENT)}px top`,
        end: `bottom top`,
        scrub: true,

        onEnter: () => setIsSticky(true),
        onLeave: () => setIsSticky(false),
        onEnterBack: () => setIsSticky(true),
        onLeaveBack: () => setIsSticky(false),
      });

      return () => {
        trigger.kill();
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      if (!isSticky && sliderRef.current) {
        gsap.set(sliderRef.current, { position: 'relative', top: 'auto' });
      }
      if (isSticky && sliderRef.current) {
        gsap.set(sliderRef.current, { position: 'sticky', top: '5.5555rem' });
      }
    }
  }, [isSticky, isMobile]);

  return (
    <div {...stylex.props(styles.container(isSliderRight))}>
      <div ref={sliderRef} {...stylex.props(styles.slider(isSliderRight))}>
        {sliderComponent}
      </div>
      <div ref={textRef} {...stylex.props(styles.textBlockWrapper(isSliderRight))}>
        {textBlockComponent}
      </div>
    </div>
  );
};
