import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  videoContainer: {
    marginTop: '80px',
    width: '100%',
    height: '50rem',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingBottom: 0,
    // '::before': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   background: 'rgba(0, 0, 0, 0.20)', // Полупрозрачный черный фон
    //   zIndex: 1,
    //   pointerEvents: 'none',
    // },
    '@media (max-width: 768px)': {
      marginTop: '0',
      height: '100vh',
      zIndex: '200',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.20)', // Затемнение
    zIndex: 2, // Затемнение выше видео, но ниже контента
    pointerEvents: 'none',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  playButton: {
    willChange: 'opacity',
    background: 'rgba(0, 0, 0, 0.60)',
    mixBlendMode: 'multiply',
    border: 'none',
    borderRadius: '4.166rem',
    padding: '0.417rem 0.4862rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    transform: 'scale(1)',
    width: '15.9028rem',
    height: '5.3472rem',
    transition: 'transform 0.3s ease',
    '@media (max-width: 768px)': {
      width: '41.667rem',
      height: '11.111rem',
      padding: '1.111rem',
      borderRadius: '12.5rem',
    },
  },
  btnBorder: {
    ':not(#__unused__) > svg': {
      width: '1.25rem',
      height: '1.875rem',
      marginLeft: '2.5694rem',
      marginRight: '1.4584rem',
      fill: '#fff',
      stroke: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '15.0695rem',
    height: '4.375rem',
    border: '1px solid #FEFAF3',
    borderRadius: '4.166rem',
    '@media (max-width: 768px)': {
      width: '39.444rem',
      height: '8.887rem',
      ':not(#__unused__) > svg': {
        width: '2.5rem',
        height: '3.333rem',
        marginLeft: '0',
        marginRight: '1.666rem',
      },
      justifyContent: 'center',
      border: '.75px solid #FEFAF3',
    },
  },
  playButtonHover: {
    transform: 'scale(1.1)',
    transition: 'transform 0.3s ease',
  },
  btnWrap: {
    position: 'absolute',
    left: '50%',
    top: '20.694rem',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 3,
    '@media (max-width: 768px)': {
      top: '50%',
    },
  },
  textContainer: {
    position: 'absolute',
    bottom: '5.555rem',
    left: '5.555rem',
    width: '59.306rem',
    height: '14.584rem',
    fontSize: '4.862rem',
    fontWeight: '500',
    lineHeight: '100%',
    textTransform: 'uppercase',
    color: '#fff',
    zIndex: 3,
    fontFamily: fonts.ttForsMedium,

    '@media (max-width: 768px)': {
      fontSize: '7.7777rem',
      bottom: '23.055rem',
      left: '4.722rem',
      width: '90.556rem',
      height: '23.333rem',
      letterSpacing: '-0.84px',
      fontWeight: '500',
    },
  },
  btnText: {
    fontFamily: fonts.ttForsRegular,
    fontSize: '1.111rem',
    fontWeight: 400,
    color: colors.primaryInverseText,
    lineHeight: '135%',
    '@media (max-width: 768px)': {
      fontSize: '3.333rem',
    },
  },
});
