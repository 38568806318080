import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { Modal } from '@/shared/ui/modal';
import { VideoPlayer } from '@/features/video-player';
import { FullScreenVideoPlayerProps } from '../model/full-screen-video-types';
import { PlayIcon } from './play-icon';

export const FullScreenVideoPlayer = ({
  previewUrl,
  videoUrl,
  playBtnLabel,
  isPlayBtn,
  text,
}: FullScreenVideoPlayerProps) => {
  const videoBackgroundRef = useRef<HTMLVideoElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (videoBackgroundRef.current) {
      videoBackgroundRef.current.muted = true;
      videoBackgroundRef.current.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  const handlePlay = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div {...stylex.props(styles.videoContainer)}>
        <div {...stylex.props(styles.overlay)}></div>
      <video
        ref={videoBackgroundRef}
        src={previewUrl}
        autoPlay={true}
        controls={false}
        loop={true}
        {...stylex.props(styles.video)}
        playsInline
      ></video>
      <h1 {...stylex.props(styles.textContainer)}>{text}</h1>
      {isPlayBtn && (
        <div {...stylex.props(styles.btnWrap)}>
          <button
            onClick={handlePlay}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            {...stylex.props(styles.playButton, isHover && styles.playButtonHover)}
          >
            <div {...stylex.props(styles.btnBorder)}>
              <PlayIcon />
              <span {...stylex.props(styles.btnText)}>{playBtnLabel}</span>
            </div>
          </button>
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {isModalOpen && <VideoPlayer url={videoUrl} autoPlay={true} />}
      </Modal>
    </div>
  );
};
