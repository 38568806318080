export const PlayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 27">
      <g clipPath="url(#clip0_4219_134)">
        <path d="M16.7094 11.9147L3.21935 1.53869C1.9041 0.527056 0 1.46469 0 3.124V23.876C0 25.5353 1.9041 26.4729 3.21935 25.4613L16.7094 15.0853C17.7503 14.2847 17.7503 12.7153 16.7094 11.9147Z" />
      </g>
      <defs>
        <clipPath id="clip0_4219_134">
          <rect width="18" height="27"  />
        </clipPath>
      </defs>
    </svg>
  );
};
